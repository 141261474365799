body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8fafb;
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.invoice-filter-label {
  color: #b5babe;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0;
  margin-right: 10px;
}

button:hover {
  box-shadow: none !important;
}

.edit-business-payment-method .MuiInputBase-input {
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
}

.MuiStack-root > .MuiTextField-root {
  min-width: 0px !important;
}
/* .MuiPopover-paper {
  max-height: 300px !important;
} */

.customer-dropdown > label:not(.MuiInputLabel-shrink) {
  top: -6px !important;
}

.account-menu > .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.3) !important;
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 30px !important;
  }
  .invoice-card {
    padding: 18px 15px 18px 15px !important;
  }
  .invoice-controls {
    padding: 0 !important;
    margin-top: 15px;
  }
}
.normal-text {
  font-size: 16px;
}
.text-link {
  text-decoration: underline;
  font-size: 12px;
  color: #473ab7;
  cursor: pointer;
}
.modal-desc {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #081159;
}
.modal-title {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 200%;

  /* Brand text color */
  color: #081159;
}
.MuiDialog-paper {
  padding: 30px !important;
}
.MuiDialogTitle-root {
  padding: 8px !important;
}
.MuiDialogContent-root {
  padding: 8px !important;
}
.MuiAvatar-root {
  font-family: "Poppins" !important;
  font-weight: 600;
}
.MuiChip-root {
  font-family: "Poppins" !important;
}
.grid-row-collapse-container {
  background: #ffffff;
  padding: 15px;
}
.MuiDialog-paper {
  padding: 20px 15px 20px 15px;
}

/* ------------------------------- ACCOUNT MENU -------------------------------- */

.account-menu > .MuiMenu-paper {
  width: 237px;
  border-radius: 10px;
}

.account-menu > .MuiMenu-paper > .MuiList-root {
  padding: 20px !important;
}
.account-menu-heading {
  font-size: 16px;
  font-weight: 700;
}
.account-menu-subtitle {
  font-size: 10px;
  font-weight: 500;
}
.account-menu-link {
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  color: #473ab7;
  margin: 0;
  cursor: pointer;
}
.account-menu .MuiMenuItem-root {
  border-radius: 10px;
  padding: 10px;
}
.MuiMenuItem-root > p {
  font-family: "Poppins" !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 10px;
}

/* ------------------------------- ACCOUNT MENU -------------------------------- */

/* -------------------------------MUI TABS-------------------------------- */
.MuiTab-root {
  font-family: "Poppins" !important;
  color: #b5babe;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  line-height: 20px;
}
.MuiTab-root.Mui-selected {
  font-family: "Poppins" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #081159 !important;
}
.MuiTabs-indicator {
  /* Customize the indicator styles if needed */
  background: linear-gradient(180deg, #8960d2 0%, #473ab7 100%) !important;
}
/* -------------------------------MUI TABS-------------------------------- */

/* -------------------------------MUI TABLE-------------------------------- */
.MuiTableHead-root {
  background: #f7f7f7;
  border-radius: 5px;
}
.MuiTableCell-head {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 20px !important;
  color: #707881 !important;
  border: 0 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
.MuiTableCell-body {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #081159 !important;
}
.MuiTableBody-root > .MuiTableRow-root {
  background: transparent !important;
  /* border-bottom: 1px solid #d9d9d9; */
  padding-top: 30px !important;
}
.MuiTableCell-root > .MuiTableCell-body {
  padding: 40px;
}
@media screen and (max-width: 992px) {
  .MuiTableCell-body {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #081159 !important;
  }
  .MuiTableCell-head {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
  .MuiTableHead-root {
    background: transparent !important;
  }
  .MuiTableCell-body {
    padding: 0px 0px 0px 0px !important;
  }
}
/* -------------------------------MUI TABLE-------------------------------- */

.MuiIconButton-root > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #616161;
}
.scrollable {
  overflow-y: scroll;
  height: calc(100vh - 150px);
}

h1 {
  font-weight: 600;
  font-size: 36px;
}
h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;
  color: #473ab7;
}
button {
  font-family: "Poppins" !important;
}
textarea {
  /* min-height: 200px !important; */
}
.white-card {
  background: #ffffff;
  border-radius: 30px;
  padding: 40px 40px 40px 40px;
}
.invoice-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 28px 35px 28px 35px;
}
.heading-primary {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 200%;
  color: #081159;
}
.heading-secondary {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 200%;
  color: #081159;
}
.subtitle-primary {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #b5babe;
}
.subtitle-secondary {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135.5%;
  color: #2f2f2f;
}
.text-none {
  /* text-transform: uppercase; */
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #081159;
}
.hover-on {
  cursor: pointer;
}
.color-primary {
  color: #2f2f2f;
}
.color-secondary {
  color: #473ab7;
}
.purple-bg {
  background: #473ab7;
}
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.bg-white {
  background: #f8fafb;
  box-shadow: 0px 4px 154px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.br-15 {
  border-radius: 15px;
}
/* -----------------------------SIDEBAR--------------------------------  */
.sidebar-pad {
  padding: 15px 20px 15px 20px;
}
.sidebar-margin {
  margin: 15px 20px 15px 20px;
}
.sidebar-root {
  background: #473ab7;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  z-index: 100;
  width: inherit;
  /* padding: 25px; */
}
.business-card {
  padding: 20px 10px 20px 10px;
}
.sidebar-links {
  padding: 20px 0px 20px 0px;
}
.sidebar-links.inactive {
  padding: 0px 0px 0px 0px !important;
}
.sidebar-link-item {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 20px;
  /* border-radius: 8px; */
  cursor: pointer;
}
.sidebar-link-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 0px 15px;
  text-align: center;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #ffffff;
}
.sidebar-link-subtext {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin: 0px 0px 0px 15px;
  text-transform: uppercase;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}

@media screen and (max-width: 1450px) {
  .sidebar-root {
    background: #473ab7;
    height: 100vh;
    overflow-y: auto;
    /* padding: 25px 10px 25px 10px; */
  }
  .sidebar-links {
    padding: 15px 0px 15px 0px !important;
  }
  .sidebar-link-item {
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 20px;
    /* border-radius: 8px; */
    cursor: pointer;
  }
  .sidebar-link-item > img {
    width: 20px;
    height: 20px;
  }
  .sidebar-link-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    margin: 0px 0px 0px 15px;
    text-align: center;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #ffffff;
  }
}

/* -----------------------------SIDEBAR--------------------------------  */

/* -----------------------------MOBILE SIDEBAR--------------------------------  */
.mobile-sidebar-root {
  padding: 15px 25px 15px 25px;
}
.business-card {
  padding: 20px 10px 20px 10px;
}
.mobile-sidebar-links {
  /* padding: 10px 0px 40px 0px; */
}
.mobile-sidebar-link-item {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 8px;
  cursor: pointer;
}

.mobile-sidebar-link-item.active {
  background: #473ab7 !important;
}

.mobile-sidebar-link-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 0px 15px;
  text-align: center;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #000000;
}

.mobile-sidebar-link-name.active {
  color: #ffffff;
}

.mobile-sidebar-link-subtext {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin: 0px 0px 0px 15px;
  text-transform: uppercase;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #000000;
}

@media screen and (max-width: 1450px) {
  .sidebar-root {
    background: #473ab7;
    height: 100vh;
    overflow-y: auto;
    /* padding: 25px 10px 25px 10px; */
  }
  .sidebar-links {
    padding: 5px 0px 20px 0px !important;
  }
  .sidebar-link-item {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 20px;
    /* border-radius: 8px; */
    cursor: pointer;
  }
  .sidebar-link-item > img {
    width: 20px;
    height: 20px;
  }
  .sidebar-link-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    margin: 0px 0px 0px 15px;
    text-align: center;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #ffffff;
  }
}

/* -----------------------------MOBILE SIDEBAR--------------------------------  */

/* -----------------------------TOP NAVBAR--------------------------------  */
.header-root {
  background: #ffffff;
  padding: 15px 35px 15px 35px;
  position: fixed;
  width: inherit;
  z-index: 2;
}
.header-user-info {
  cursor: pointer;
}
.header-user-info > p {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: -0.01em;
  margin: 0 0px 0 0;
  color: #000000;
}
/* -----------------------------TOP NAVBAR--------------------------------  */

/* -----------------------------BOTTOM NAVBAR--------------------------------  */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #473ab7;
  padding: 20px 0 20px 0;
}
.bottom-nav-item,
.bottom-nav-home-item {
  cursor: pointer;
}

.bottom-nav-item > p {
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.bottom-nav-home-item {
  background: #ffffff;
  border-radius: 100px;
}

.bottom-nav-home-item > p {
  font-weight: 500;
  font-size: 14px;
  color: #473ab7;
}
/* -----------------------------BOTTOM NAVBAR--------------------------------  */

.main-panel-root {
  /* background: #f8fafb; */
  box-shadow: 0px 4px 154px rgba(0, 0, 0, 0.06);
  /* overflow-y: scroll; */
  min-height: 100vh;
  padding: 105px 35px 40px 35px;
}
@media screen and (max-width: 1400px) {
  .main-panel-root {
    padding: 105px 35px 47px 35px !important;
  }
}

/* ----------------------------SIGN IN SCREEN-------------------------------- */

/* executes if the viewport is 992px wide or less - LG */
@media screen and (max-width: 992px) {
  .login-form-logo {
    margin-bottom: 0px !important;
    max-width: 280px !important;
    max-height: 100px !important;
  }
  .MuiChip-label {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .register-form-logo {
    height: auto;
    width: auto;
    max-width: 280px !important;
    max-height: 100px !important;
  }
  .white-card {
    padding: 20px 10px 20px 10px !important;
  }
}
.login-holder {
  min-height: 100vh;
}
.login-form-graphic-2 {
  width: 50vw;
  height: 55vh;
}
.login-form-graphic-1-mobile {
  width: 100%;
  height: 100%;
}
.login-form-logo {
  margin-bottom: 80px;
  height: auto;
  width: auto;
  max-width: 500px;
  max-height: 170px;
}
.login-graphic {
  background: #473ab7;
  height: 100vh;
}
.login-form {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
}
/* ----------------------------SIGN IN SCREEN-------------------------------- */

/* ----------------------------REGISTER SCREEN-------------------------------- */
.register-form-logo {
  height: auto;
  width: auto;
  max-width: 500px;
  max-height: 170px;
}

/* -----------------------------CREATE BUSINESS SCREEN--------------------------------  */
/* executes if the viewport is 576 wide or less - LG */
@media screen and (max-width: 576px) {
  .create-business-form-logo {
    margin-top: 30px;
  }
  .dashboard-greeting-text {
    color: #081159 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important; /* 125% */
  }
  .dashboard-greeting-title {
    color: #081159 !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .welcome-box {
    padding: 15px 19px 15px 19px !important;
  }
  .price-features {
    font-size: 12px !important;
  }
}
.create-business-form-logo {
  height: auto;
  width: auto;
  max-width: 275px;
  max-height: 90px;
}

/* ----------------------------DASHBOARD-------------------------------- */
.welcome-box {
  border-radius: 15px;
  padding: 20px 24px 20px 24px;
}
.dashboard-greeting-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
}
.dashboard-greeting-title {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dashboard-card-title {
  color: #473ab7;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.dashboard-card-text {
  color: #473ab7;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.dashboard-heading {
  color: #081159;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mobile-welcome-box-text {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* ----------------------------DASHBOARD-------------------------------- */

/* ----------------------------INVOICE CREATE EDIT-------------------------------- */
.invoice-item-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* color */
  color: #374151;
}
.invoice-details-header {
  background: #473ab7;
  border-radius: 7px 7px 0px 0px;
  padding: 19px 35px 19px 35px;
}
.invoice-details-header h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
}
.invoice-details-header p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.4;
}
.invoice-details-footer {
  padding: 19px 35px 19px 35px;
  /* border-radius: 0px 0px 10px 10px; */
}
.invoice-details-footer > div > div > div > p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.invoice-details-root {
  border: 1px solid #473ab7;
  border-radius: 10px;
}
.billed-to-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #374151;
}
.billed-to-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}
.invoice-add-item {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #9590a8 !important;
}
.invoice-item-total {
  background: #473ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  /* secondary */
  border: 1px solid #473ab7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  height: 100%;
}
.invoice-vat-inclusive {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.invoice-summary-text {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 11px;
  /* color */
  color: #374151;
}
.heading-secondary-invoice {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #374151;
}
.invoice-status-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 0;
}
.invoice-status-subtext {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  margin-bottom: 0;
  color: #374151;
}
.invoice-coming-soon {
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #473ab7;
}
.invoice-control-input-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  .invoice-controls {
    width: 28%;
    height: 100vh;
    position: fixed;
    top: 104px;
    right: 0;
    overflow-y: scroll;
  }
}

/* ----------------------------INVOICE CREATE EDIT-------------------------------- */

/* ----------------------------CUSTOMER DETAILS-------------------------------- */
.customer-info-heading {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.customer-info-content {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.customer-stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.customer-total-revenue-container {
  border-radius: 17px;
  background: #ebf8f1;
  padding: 20px;
  display: flex;
  align-items: center;
}
.customer-total-invoices-container {
  border-radius: 17px;
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
}
.customer-total-due-container {
  border-radius: 17px;
  background: #ffe6ef;
  padding: 20px;
  display: flex;
  align-items: center;
}
.customer-stats-heading {
  margin: 0;
  color: #616161;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.customer-stats-content {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
/* ----------------------------CUSTOMER DETAILS-------------------------------- */

/* ----------------------------DATE PICKER-------------------------------- */
.react-datepicker__input-container > input {
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  border: 1px solid #dddada !important;
  padding: 4px !important;
}
.react-datepicker__input-container > input:focus {
  border: 1px solid #473ab7 !important;
  outline: none !important;
}
/* ----------------------------DATE PICKER-------------------------------- */

/* ----------------------------SELECT PLAN-------------------------------- */

.select-plan-bg {
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.select-plan-container {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
  padding: 25px 40px 25px 40px;
}
.select-plan-heading {
  color: #081159;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.price-container {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 5px 20px;
}
.price-heading {
  color: #473ab7;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.price-value {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.price-freq {
  color: #616161;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.price-features {
  /* color: #616161;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #616161;
}
.coming-soon {
  font-size: 8px;
  font-weight: 400;
  margin-left: 5px;
}
.upgrade-plan-price-container {
  border-radius: 14px;
  border: 0.5px solid #473ab7;
  padding: 25px 30px 25px 30px;
}
.upgrade-plan-price-container.business {
  border-radius: 14px;
  border: 1px solid #473ab7;
  box-shadow: 0px 0px 30px 0px #0000001a;
}
.upgrade-plan-price-container .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #473ab7;
  margin-bottom: 0;
}
.upgrade-plan-price-container .value {
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}
.upgrade-plan-price-container .freq {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #616161;
  margin: 0;
}
.decimal {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.upgrade-plan-price-container ul {
  padding-left: 22px;
}
.upgrade-plan-price-container li {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #616161;
}
.strike {
  text-decoration: line-through;
  color: #cccccc !important;
}
/* ----------------------------SELECT PLAN-------------------------------- */
